<template>
  <div class="animation-typewriting">
    <slot></slot>
  </div>
</template>
<script>

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

import Splitting from "splitting";

export default {
  name: "Typewrite",
  mounted() {
    new Splitting({target: this.$el, by: 'chars'});
    //console.log(this.$el, split)
  }
}

</script>

<style lang="scss">
.animation-typewriting {
  .word {
    white-space: nowrap;
  }

  &.splitting {
    .char {
      display: inline-block;
      animation: fadeInRight .3s;
      animation-fill-mode: both;
      animation-delay: calc(20ms * var(--char-index));
    }
  }
}
</style>