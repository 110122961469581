export class GaTrack {

    static settings = {
        //gaCode: process.env.GA_MEASUREMENT_ID,
        //ga4Code: process.env.GA4_MEASUREMENT_ID,
        //domains: ['fornonprofit.it', 'granter.it', 'italianonprofit.it']
    };

    /**
     * // viene sempre chiamata, agiste solo nel momento in cui ci sono i tracciamenti attivi
     // manda evento ad analitics
     // tiggera cose su Google tag manager
     // manda ad hotjar
     // ecc...
     * @param object
     * @returns {boolean}
     */
    static sendEventToAnalytics(object = {}) {
        window.dataLayer = window.dataLayer || [];

        let type = object.type ? object.type : "event";
        let result = false;
        let path = null;
        let title = null;
        let dataObject = null;
        switch (type) {
            case "pwa-pageview":
                path = object.path ? object.path : window.location.pathname;
                title = object.title ? object.title : document.title;
                window.dataLayer.push({
                    event: 'pwa-pageview',
                    page: {
                        path: path,
                        title: title
                    }
                });
                break;
            case "event":
                //result = window.ga('send', object['type'], object['category'], object['action'], object['label']);
                dataObject = {
                    'event': 'eventTracking',
                    'category': object['category'],
                    'action': object['action'],
                    'label': object['label'],
                };
                if(Object.keys(object).indexOf('value')> -1) dataObject['value'] = object.value;
                result = window.dataLayer.push(dataObject);
                break;
            case "purchase":
                //result = window.ga('send', object['type'], object['category'], object['action'], object['label']);
                result = window.dataLayer.push({
                    event:'purchase',
                    ecommerce:object.data
                });
                break;
        }

        return result;
    }

    /**
     *  @function shortcut to sendEventToAnalytics
     */
    static sendPageView(object = {}) {
        let canSend = false;
        window.dataLayer.forEach(function(i){
            if(i.event === "ga3_fired" || i.event === "ga4_fired") canSend = true;
        })
        if(!canSend){
            console.log('Consenso non abilitato...non mando pageview');
            return;
        }
        let path = object.path ? object.path : window.location.pathname;
        let title = object.title ? object.title : document.title;
        GaTrack.sendEventToAnalytics({
            type: 'pwa-pageview',
            path: path,
            title: title,
        });
    }

}