import Vue from 'vue'
import VueRouter from 'vue-router'

import MainFilters from "../dictionary/MainFilters";

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Donatori from '../views/Donatori.vue'
import Donazioni from '../views/Donazioni.vue'
import DettaglioDonazione from '../views/dettaglio/Donazione.vue'
import DettaglioDonatore from '../views/dettaglio/Donatore.vue'
import Segnalazione from '../views/Segnalazione.vue'
import {GaTrack} from "@/utils/gaTrack";

Vue.use(VueRouter)
let page_loaded = false;
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Emergenza Ucraina: come donare alle organizzazioni attive',
            metaTags: [
                {
                    name: 'description',
                    content: 'Orientati tra le molte iniziative selezionate e promosse da organizzazioni non profit e istituzioni per fronteggiare l\'emergenza.'
                },
                {
                    property: 'og:title',
                    content: 'Emergenza Ucraina: come aiutare - Homepage'
                },
                {
                    property: 'og:description',
                    content: 'Scopri come puoi aiutare e sostenere le organizzazioni e le istituzioni impegnate nel fronteggiare l\'emergenza in Ucraina.'
                }
            ]
        }
    },
    {
        path: '/il-progetto',
        name: 'About',
        meta: {
            title: 'Emergenza Ucraina, come aiutare - Il progetto di Italia non profit',
            metaTags: [
                {
                    name: 'description',
                    content: 'Il progetto nasce in risposta al bisogno di raccordo e orientamento della società civile per offrire ognuno il proprio aiuto nell\'emergenza in Ucraina.'
                },
                {
                    property: 'og:title',
                    content: 'Emergenza Ucraina: come aiutare - Il progetto'
                },
                {
                    property: 'og:description',
                    content: 'Il progetto nasce in risposta al bisogno di raccordo e orientamento della società civile per offrire ognuno il proprio aiuto nell\'emergenza in Ucraina.'
                }
            ]
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: About
    },
    {
        path: '/segnalazioni',
        name: 'Segnalazioni',
        meta: {
            title: 'Italia non profit - Emergenza Ucraina - Segnalazioni',
            metaTags: [
                {
                    name: 'description',
                    content: 'Partecipa segnalando un\'iniziativa di aiuto e sostegno alla popolazione ucraina portata avanti da un\'organizzazione non profit, un\'istituzione o un\'azienda.'
                },
                {
                    property: 'og:title',
                    content: 'Emergenza Ucraina: come aiutare - Segnalazioni'
                },
                {
                    property: 'og:description',
                    content: 'Partecipa segnalando un\'iniziativa di aiuto e sostegno alla popolazione ucraina portata avanti da un\'organizzazione non profit, un\'istituzione o un\'azienda.'
                }
            ]
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Segnalazione
    },
    {
        path: '/aziende-e-istituzioni',
        name: 'Donatori',
        meta: {
            title: 'Emergenza Ucraina - Aiuti - Aziende e Istituzioni',
            metaTags: [
                {
                    name: 'description',
                    content: 'Scopri in che modo Aziende e Istituzioni si stanno muovendo a sostegno della popolazione ucraina per fronteggiare la crisi umanitaria.'
                },
                {
                    property: 'og:title',
                    content: 'Emergenza Ucraina: Aziende e Istituzioni - Tutti gli aiuti'
                },
                {
                    property: 'og:description',
                    content: 'Scopri in che modo Aziende e Istituzioni si stanno muovendo a sostegno della popolazione ucraina per fronteggiare la crisi umanitaria.'
                }
            ]
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Donatori,
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: '/aziende-e-istituzioni/:id-:slug',
                component: DettaglioDonatore,
                name : "DettaglioDonatore"
            },
        ],
    },
    {
        path: '/donazioni/:slug',
        name: 'Donazioni',
        /*meta: {
            title: 'Ucraina - Donazioni',
            metaTags: [
                {
                    name: 'description',
                    content: 'The home page of our example app.'
                },
                {
                    property: 'og:description',
                    content: 'The home page of our example app.'
                }
            ]
        },*/
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Donazioni,
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                name : "DettaglioDonazione",
                path: '/donazioni/:slug/:id-:name',
                component: DettaglioDonazione,
            },
        ],
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    base: process.env.VUE_APP_BASEPATH,
    scrollBehavior(to, from, savedPosition) {
        //console.log(to.name)
        if (savedPosition && (to.name === "Donazioni" || to.name !== "Donatori" || to.name !== "Home")) {
            return savedPosition
        } else {
            console.log("scrolltop");
            setTimeout(function(){
                //alert("ciao")
                window.scrollTo(0, 0);
            },100)
            return { top: 0 }
        }
    },
})

/*router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = toDepth < fromDepth ? 'animate__aniamated animate__slideInRight' : 'animate__aniamated animate__slideInLeft'
});*/

router.afterEach(( to ) => {
    /**
     * Verifico che la prima volta non venga spedito l'evento di pageview
     */
    if(page_loaded){
        setTimeout(function () {
            console.log("Invio pageview",process.env.VUE_APP_BASEPATH + to.path);
            GaTrack.sendPageView({
                path:process.env.VUE_APP_BASEPATH + to.path,
            })
        },100);
    }
    if(!page_loaded) page_loaded = true;
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.

    let nearestWithTitle,nearestWithMeta,previousNearestWithMeta;

    const match = to.matched.slice().reverse()[0]

    if(match.name === 'Donazioni' && to.params.slug)
    {
        nearestWithTitle = MainFilters.getFilterBySlug(to.params.slug)//.find(r => r.meta && r.meta.title)
        nearestWithMeta = MainFilters.getFilterBySlug(to.params.slug)//.find(r => r.meta && r.meta.metaTags)
    } else {
         nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
         nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    }

    previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router
