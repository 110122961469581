<template>
  <div class="animation-lines-reveal">
    <slot></slot>
  </div>
</template>
<script>

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

import Splitting from "splitting";

export default {
  name: "Lineswrite",
  mounted() {
    new Splitting({target: this.$el, by: 'lines'});
    //console.log(this.$el, split)
  }
}

</script>

<style lang="scss">
.animation-lines-reveal {
  overflow: hidden;

  .whitespace {
    animation: fadeInUp 1.25s;
    animation-fill-mode: both;
    &:before {
      content: " ";
    }
  }

  .word {
    text-decoration: inherit;
    display: inline-block;
    animation: fadeInUp 1.25s;
    animation-fill-mode: both;
    animation-delay: calc(.5s + (.2s * var(--line-index)));
  }
}
</style>