<template>
  <div class="floating-view__wrapper  ">

    <a @click="close()" class="animate__animated animate__fadeIn floating-view__backdrop">
      <div v-if="loading" class="loading-text__wrapper">
        <div class="loading-text">
          <typewrite>CARICAMENTO</typewrite>
        </div>
      </div>
    </a>

    <div v-if="post" class="floating-view__scroller animate__animated animate__slideInUp">

      <div  class="floating-view__container single-donatore">
        <div class="floating-view__close">
          <a @click="close()" class="">
            <i class="ri-close-line"></i>
          </a>
        </div>
        <div>
          <div class="">

            <div class="row py-md-5">
              <div class="col-md-8 offset-md-2">

                <div class="single-donatore__header-img" v-if="post.logo">
                  <img :src="`${post.logo.cropped.fileUrl}`" alt="" class="">
                </div>

                <h1 class="font-weight-bold h2">
                  {{ post.nome }}
                </h1>

                <div v-if="post.descrizione">

                  <p class="" v-html="parseContent(post.descrizione)">
                  </p>
                </div>

                <div v-if="post.iniziativa" class="border-top py-5">

                  <div class="row">
                    <div class="col-md-4">
                      <h3 class="h3 font-weight-bold">Come si è attivata: </h3>
                    </div>
                    <div class="col-md-8">
                      <ul class="list-unstyled">
                        <li class="" v-for="(iniziativa,k) in post.iniziativa" :key="k">
                          <div class="  mb-5">
                            <div class=""><h5 class="">{{ iniziativa.tipo }}</h5>
                              <p class=""> {{ iniziativa.abstract }}</p>
                              <a rel="nofollow" :href="iniziativa.url" target="_blank"
                                 class="btn donatore-btn d-inline-flex">
                                Scopri i dettagli
                                <i class="ri-arrow-right-line"></i>
                              </a></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-md-9">
                      <div class="mb-5">

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import router from "../../router";
import Typewrite from "@/components/Typewrite";

//const _ = require('lodash');

export default {
  data() {
    return {
      post: null,
      loading: true
    }
  },
  components : {
    Typewrite
  },
  computed: {},
  mounted() {
    document.body.classList.add('floating-view');
  },
  destroyed() {
    document.body.classList.remove('floating-view')
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchData()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  methods: {
    close() {
      if (history.length > 0)
        router.back();
      else
        router.push({name: 'Donatori'})
    },
    parseContent(content) {
      return content.replace(/\n/g, "<br />");
    },

    fetchData() {
      Api.get({
        action: 'web/ucrainadonatori/getlist',
        id: this.$route.params.id
      }).then((response) => {
        // fix per ios scroll

        this.post = response.data.hits.hits[0]._source;
      })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>