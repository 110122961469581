<template>
  <div class="btn-group mr-md-2">
    <a data-ga-category="Ucraina" :data-ga-label="`${GAEventLabel}`" data-ga-action="ux" :href="`http://www.facebook.com/sharer.php?u=${absPath}`" :class="`ga-track btn ${btnClass}`" target="_blank">
      <i class="ri-facebook-box-fill ri-lg"></i>
    </a>
    <a data-ga-category="Ucraina" :data-ga-label="`${GAEventLabel}`" data-ga-action="ux" :href="`https://twitter.com/share?url=${absPath}&amp;text=${absPath}&amp;hashtags=`" :class="`ga-track btn ${btnClass}`"  target="_blank">
      <i class="ri-twitter-fill ri-lg"></i>
    </a>
    <a data-ga-category="Ucraina" :data-ga-label="`${GAEventLabel}`" data-ga-action="ux" :href="`whatsapp://send?text=${absPath}`" :class="`ga-track btn ${btnClass}`"  target="_blank">
      <i class="ri-whatsapp-fill ri-lg"></i>
    </a>
    <a data-ga-category="Ucraina" :data-ga-label="`${GAEventLabel}`" data-ga-action="ux" :href="`https://www.linkedin.com/shareArticle?mini=true&url=${absPath}`" :class="`ga-track btn ${btnClass}`"  target="_blank">
      <i class="ri-linkedin-fill ri-lg"></i>
    </a>
  </div>
</template>

<script>
export default {
  props : ['btnClass'],
  mounted() {
    console.log(this.$route)
  },
  computed : {
    absPath(){
      return location.href;
    },

    GAEventLabel(){
      if(location.pathname.indexOf('/emergenza-ucraina/donazioni/fare-volontariato') === 0){
        return 'btn-condividi-pagina-volontariato';
      } else if(location.pathname.indexOf('/emergenza-ucraina/donazioni/donazioni-in-denaro') === 0){
        return 'btn-condividi-pagina-raccolta-fondi';
      }else if(location.pathname.indexOf('/emergenza-ucraina/donazioni/offrire-ospitalità') === 0){
        return 'btn-condividi-pagina-accoglienza';
      } else if (location.pathname.indexOf('/emergenza-ucraina/donazioni/donazioni-di-beni') === 0) {
        return 'btn-condividi-pagina-donazioni-beni';
      }

      return 'btn-condividi-iniziativa';
    }
  }
}
</script>