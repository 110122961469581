<template>
  <div>
    <div class="view">
      <div class="h4uk__jumbo-serp">
        <div class="h4uk__jumbo-serp__bg-img">
          <img :src="`${category.bgImg}`" alt="">
        </div>
        <div class="container">
          <div class="pt-md-5 py-3 text-white mb-5">
            <div class="row align-items-end">
              <div class="col-12">
                <div @click="back()" class="back-btn">
                  <i class="ri-arrow-left-line"></i>
                  Home
                </div>
              </div>
              <div class="col-md-6">
                <typewrite>
                  <h1>
                    {{ category.description }}
                  </h1>
                </typewrite>
              </div>

              <div class="col-md-5 offset-md-1 ">
                <lineswrite>
                  <p class="mb-0"> {{ category.long_description }}</p>
                </lineswrite>
              </div>
            </div>


            <hr class="my-4">
            <div class="donazioni__toolbar">

              <button class="donazioni__toolbar-filters-toggle btn" @click="toggleFilter">
                Filtra
                <i class="ri-equalizer-line"></i>
              </button>

              <div class="donazioni__toolbar-share-btns">
                <share-btns :btn-class="'btn-secondary'"></share-btns>
              </div>


              <div class="donazioni__toolbar-text">
                Ci sono <strong>{{ resultLength }} </strong>iniziative <span
                  class="d-none d-md-inline">attive e</span> verificate al {{ today }}
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div v-if="loading" class="p-5 text-muted text-center">
          <typewrite>
            CARICAMENTO
          </typewrite>
        </div>

        <div class="d-flex h-100 flex-column" v-if="results">
          <ul class="h4uk-iniziative">
            <li class="animate__animated animate__fadeIn" :style="`animation-delay : ${index * .1}s`"
                v-for="(item, index) in results" :key="item._source.slug">

              <router-link data-ga-category="Ucraina" :data-ga-label="`click-scheda-ente-${item._source.id}`"
                           data-ga-action="ux"
                           :to="`/donazioni/${category.slug}/${item._source.id}-${item._source.slug}`"
                           class="ga-track h4uk-iniziative__item">

                <div class="h4uk-iniziative__thumb">
                  <img :src="`${item._source.onp.logo.cropped.fileUrl}`" alt="" class="">

                </div>
                <div class="h4uk-iniziative__label">

                  <v-clamp autoresize :max-lines="1" class="h4uk-iniziative__title">
                    {{ item._source.onp.name }}
                  </v-clamp>
                  <div class="h4uk-iniziative__description">
                    <v-clamp autoresize :max-lines="2">
                      {{ item._source.titolo }}
                    </v-clamp>
                  </div>


                </div>


              </router-link>
            </li>
          </ul>
          <div class="mb-5">
            <div class="text-muted mb-5 p-md-5" style="line-height: 1.2">
              <small>
                Italia non profit verifica che le attività di raccolta fondi siano promosse da organizzazioni non
                profit e istituzioni pubbliche. Nella sezione relativa alle iniziative a favore dell’Ucraina ,tutti i progetti sono stati
                vagliati ed elaborati con cura, facendo attenzione che rispondano a requisiti di attendibilità,
                correttezza, completezza e attualità. Errori, inesattezze ed omissioni involontarie sono però possibili.
                Per segnalazioni è possibile <a target="_blank"
                                                href="https://italianonprofit.it/strumenti/form-segnalazioni-ucraina-2022/">compilare
                il
                form</a> o scrivere a: <a href="mailto:info@italianonprofit.it">info@italianonprofit.it</a>. Italia non
                profit
                declina ogni responsabilità in merito ad errori e omissioni relative ai contenuti pubblicati e in merito
                ai successivi esiti ed impieghi delle iniziative.
              </small>
            </div>
          </div>
        </div>


      </div>
    </div>
    <router-view></router-view>
  </div>
</template>


<script>

import Lineswrite from "../components/Lineswrite";
import ShareBtns from "../components/ShareBtns";

const moment = require('moment');
const _ = require('lodash');
import MainFilters from "../dictionary/MainFilters";
import Api from "@/Api";
import VClamp from 'vue-clamp'
import router from "@/router";
import Typewrite from "@/components/Typewrite";
import SubtypeFiltersStore from "@/dictionary/SubtypeFiltersStore";

import {bus} from '@/main'
import {GaTrack} from "@/utils/gaTrack";


export default {

  data() {
    return {
      loading: false,
      results: null,
      all: null,
      error: null,
      count: SubtypeFiltersStore.count
    }
  },
  computed: {
    today() {
      moment.locale('it');
      return moment().format("D MMMM")
    },
    resultLength() {
      //console.log(this.results)
      return this.results ? Object.keys(this.results).length : 0;
    },
    category() {
      return MainFilters.getFilterBySlug(this.$route.params.slug)
    },
  },


  created() {
    this.fetchData();
    bus.$on('applyFilters', (data) => {
      this.applyFilters(data);
    })

  },

  methods: {

    toggleFilter() {

      bus.$emit('toggleFilers',this.category);
    },
    back() {

      router.push({name: 'Home'})

    },
    parseTags(tags) {
      if (_.isArray(tags)) return tags;
      return tags.split(',')
    },
    applyFilters(checkFilters) {
      console.log(checkFilters)
      if (!checkFilters || Object.keys(checkFilters).length === 0 || (checkFilters.subtype === '' && checkFilters.regioni === '')) {
        this.results = _.cloneDeep(this.all);
      } else {
        let filtered = [];
        // Filtro per tipo
        if (checkFilters.subtype !== '') {
          _.forEach(this.all, (item) => {
            let post = item._source;
            let types = [];
            // uniformo il tipo del post in un array
            // se è un array glielo ritorno
            if (_.isArray(post.tipo)) {
              types = post.tipo;
              // se è una stringa
              /*
            } else if (post.tipo === 'Altro') {
              types.push(post.tipo_altro);*/
              //return new Array(this.post.tipo_altro);
            } else {
              types.push(post.tipo);
            }
            if (types.indexOf(checkFilters.subtype) > -1)
              filtered.push(item);
          });


          GaTrack.sendEventToAnalytics({
            type:'event',
            category:'Ucraina',
            action:'ux',
            label:`filtro-tipo-${this.category.gaLabel}`
          });

        }
        if (checkFilters.regioni !== '') {
          _.forEach(this.all, (item) => {
            let post = item._source;

            if (post.onp && post.onp.regione === checkFilters.regioni)
              filtered.push(item)
          });
          GaTrack.sendEventToAnalytics({
            type:'event',
            category:'Ucraina',
            action:'ux',
            label:`filtro-regione-${this.category.gaLabel}`
          });
        }

        this.results = filtered;
      }
    },

    fetchData() {
      this.error = this.results = null
      this.loading = true;

      Api.get({
        type: this.category.type,
        action: 'web/ucraina/getlist'
      })
          .then((response) => {
            this.loading = false;
            this.results = _.shuffle(response.data.hits.hits);
            //console.log(this.results)
            this.all = _.cloneDeep(this.results);

            SubtypeFiltersStore.setFilters(this.all);
          })
          .catch((e) => {
            console.log(e.message)
            this.error = e.message;
          })
          .finally(() => {
            this.loading = false
          })

    },
  },
  components: {
    Typewrite,
    Lineswrite,
    //Typewrite,

    VClamp,
    ShareBtns
  }

}

</script>

