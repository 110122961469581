/*

{"value": "raccolta_fondi", name: "Raccolta Fondi"},
{"value": "volontariato", name: "Volontariato"},
{"value": "donazione_beni", name: "Donazione di Beni"},
{"value": "donazione_sangue", name: "Donazione di Sangue"},
{"value": "altro", name: "Altro (specificare)"},

 */



import imgVolontariato from "@/assets/images/joel-muniz-3k3l2brxmwQ-unsplash.jpg"
import imgOspitalita from "@/assets/images/tobi-w38wBWIliw4-unsplash.jpg"
import imgBeni from "@/assets/images/beni.jpg"
import imgDenaro from "@/assets/images/denaro.jpg"
import Filters from "@/dictionary/Filter.json"

const _ = require('lodash');

const images = {
    "fare-volontariato"     : imgVolontariato,
    "donazioni-in-denaro"   : imgDenaro,
    "donazioni-di-beni"     : imgBeni,
    "offrire-ospitalità"    : imgOspitalita,
}

const filters = {

    getFilterByType(type) {
        let res;
        _.forEach(filters.dictionary, (i) => {
            if (i.type === type) res = i;

        });
        return res;
    },
    getFilterBySlug(slug) {
        let res;
        _.forEach(filters.dictionary, (i) => {
            if (i.slug === slug) res = i;

        });
        return res;
    },
    dictionary : (function(){
        //let res;
        _.forEach(Filters,(i)=>{
            i.bgImg = images[i.slug];
        });
        return Filters;
    })()
}

export default filters;