<template>
  <div>
    <div class="view donatori bg-white">
      <div class="h4uk__jumbo-donatori ">
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <a href="https://italianonprofit.it" target="_blank">
                <img src="@/assets/images/NERO.svg" alt="italia non profit" style="height: 20px;"
                     class="mb-5">
              </a>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row align-items-end">
            <div class="col-md-6 offset-md-1">
              <div class="h4uk-headline">
                <h1 class="h4uk-headline__center">
                  Gli Aiuti da aziende e istituzioni
                </h1>
              </div>
            </div>
            <div class="col-md-5 ">
              <lineswrite>
                <p class="lead">
                  Quali sono gli attori sociali che si stanno mobilitando per supportare i civili?
                </p>
              </lineswrite>
            </div>
          </div>
        </div>

        <div class="py-3 mt-md-5 mt-3">
          <div class="container">
            <div class="row">
              <div class="col-md-10 offset-md-1">
                <div
                    class="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between border-tops">
                  <div class="d-md-flex align-items-center">
                    <div class="" style="line-height: 1">
                      Ci sono più di <strong>{{ resultLength }} </strong> soggetti <span class="d-none d-md-inline">attivi e</span>
                      verificati al {{ today }}
                    </div>
                  </div>
                  <div class="ml-md-auto mt-3 mt-md-0 ">
                    <share-btns :btn-class="'btn-light'"></share-btns>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light">
        <div class="container pt-md-5 pt-3">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <button class="btn btn-sm rounded-pill mr-2 mb-2 pr-3" @click="setFilter('type','')"
                      :class="{ 'btn-outline-secondary' : '' !== activeFilters.type, 'btn-secondary' :  '' === activeFilters.type }">
                <i :class="{'ri-checkbox-blank-circle-line' : '' !== activeFilters.type ,'ri-checkbox-circle-line' : '' === activeFilters.type ,}"></i>
                Mostra tutti
              </button>
              <button class="btn btn-sm rounded-pill mr-2 mb-2 pr-3" v-for="filter in filters.types"
                      :class="{ 'btn-outline-secondary' : filter!== activeFilters.type, 'btn-secondary' : filter === activeFilters.type }"
                      :key="filter" @click="setFilter('type',filter)">
                <i :class="{'ri-checkbox-blank-circle-line' : filter !== activeFilters.type ,'ri-checkbox-circle-line' : filter === activeFilters.type ,}"></i>
                {{ filter }}
              </button>
            </div>
          </div>

        </div>
        <div class="container pb-5">
          <div class="row">
            <div class="col-md-10 offset-md-1 py-5">


              <div v-if="loading" class="p-5 text-muted text-center">
                <typewrite>
                  CARICAMENTO
                </typewrite>
              </div>
              <div v-if="results">
                <ul class="h4uk-donatori" v-if="results">
                  <li class="animate__animated animate__zoomIn" :style="`animation-delay : ${index * .2}s`"
                      v-for="(item, index) in filteredResults" :key="donorSlug(item._source.id)">

                    <router-link :to="`/aziende-e-istituzioni/${item._source.id}-${donorSlug(item._source.nome)}`"
                                 class="h4uk-donatori__item">

                      <div class="h4uk-donatori__thumb" v-if="item._source.logo">
                        <img :src="`${item._source.logo.cropped.fileUrl}`" alt="" class="">
                      </div>
                      <div class="h4uk-donatori__label">

                        <div class="h4uk-donatori__title">
                          <v-clamp autoresize :max-lines="3">
                            {{ item._source.nome }}
                          </v-clamp>
                        </div>
                        <v-clamp autoresize :max-lines="3" class="text-muted">
                          {{ item._short_desc }}
                        </v-clamp>
                      </div>

                    </router-link>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import router from "@/router";

const slugify = require('slugify')
import Api from "../Api";
import Typewrite from "../components/Typewrite";
import Lineswrite from "../components/Lineswrite";
import _ from "lodash";
import VClamp from "vue-clamp";
import moment from "moment";
import ShareBtns from "@/components/ShareBtns";


export default {
  components: {
    Typewrite,
    Lineswrite,
    VClamp,
    ShareBtns
  },
  data() {
    return {
      loading: true,
      error: false,
      results: {},
      activeFilters: {
        type: ''
      }
    }
  },
  computed: {
    filters() {
      let types = {};
      _.forEach(this.results, (i) => {
        let post = i._source;
        if (post.iniziativa) {
          _.forEach(post.iniziativa, (iniziativa) => {
            if (!types[iniziativa.tipo_public]) {
              types[iniziativa.tipo_public] = iniziativa.tipo_public
            }
          })
        }
      });

      return {
        types: types
      }
    },
    filteredResults() {
      let res = []

      // filtro per tipo_public
      if (this.activeFilters.type === '') return _.cloneDeep(this.results);
      else {
        _.forEach(this.results, (item) => {

          let post = item._source;
          let match = false;

          if (post.iniziativa) {
            _.forEach(post.iniziativa, (iniziativa) => {
              if (iniziativa.tipo_public === this.activeFilters.type) {
                match = true;
              }
            })
          }

          if (match) {
             res.push(item)
          }
        });
      }

      return _.shuffle(res);
    },
    today() {
      moment.locale('it');
      return moment().format("D MMMM")
    },
    resultLength() {
      /*      let iniziative;
            _.forEach(this.results,(i)=>{
              let post = i._source;
              if(post.)
            })*/
      //console.log(this.results)
      return this.filteredResults ? Object.keys(this.filteredResults).length : 0;
    },
  },
  created() {

    this.fetchData();
  },
  methods: {
    setFilter(filterKey, filter) {
      this.activeFilters[filterKey] = filter;
    },
    back() {

      if (history.length > 0)
        router.back();
      else
        router.push({name: 'Home'})

    },
    donorSlug(name) {
      return slugify(name, {
        strict: true,
        lower: true
      })
    },
    fetchData() {
      this.error = this.results = null
      this.loading = true;
      /*
      action: web/ucraina/getlist
      url: /assets/components/inp/ajaxconnector.php
       */
      Api.get({

        action: 'web/ucrainadonatori/getlist'
      })
          .then((response) => {
            this.loading = false;

            _.forEach(response.data.hits.hits, (i) => {

              if (i._source.iniziativa && Object.keys(i._source.iniziativa).length > 0) {
                i._short_desc = i._source.iniziativa['iniziativa[0]'].abstract
              } else {
                i._short_desc = i._source.descrizione;
              }
            })
            this.results = response.data.hits.hits;
            this.all = _.cloneDeep(this.results);
          })
          .catch((e) => {
            console.log(e.message)
            this.error = e.message;
          })
          .finally(() => {
            this.loading = false
          })

    },
  },
}
</script>
