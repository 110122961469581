const axios = require('axios');
const _ = require('lodash');


class Api {

    static defaultParams = {
        get: {
            url: `${process.env.VUE_APP_API_URL}/?rdm=` + new Date().getTime(),
            params: {}
        },
        post: {
            url: `${process.env.VUE_APP_API_URL}/?rdm=` + new Date().getTime(),
            data: {}
        }
    };

    static errorHandling(error) {
        if (error.config.params)
            console.log(error.config.params.action, error.response.status);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);

            //Deus.$emit('openModal', {key: 'app:unauthorized'})
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
        }
        //console.log(error.config);

        throw error;
    }


    /**
     *
     * @param {object} customParameters
     * @returns {*}
     */
    static get(customParameters) {
        var parameters;
        parameters = _.extend({}, {
            //security: Config.options.ajax_nonce,
        }, this.defaultParams.get.params, customParameters);
        //console.log(parameters);
        return axios.get(this.defaultParams.get.url,{

            params: parameters,

        })
            .catch((error) => {
                this.errorHandling(error)
            })
    }


    /**
     *
     * @param {object} customData
     * @returns {*}
     */
    static post(customData) {
        let data;
        data = _.extend({}, {}, this.defaultParams.post.data, customData);

        let formData = new FormData();

        for (var key in data) {
            formData.append(key, data[key])
        }
        return axios.post(this.defaultParams.post.url, formData, {
            //withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data'},
            cache: false,
        }).catch((error) => {
                this.errorHandling(error)
            }
        )
    }
}

export default Api;