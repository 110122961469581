<template>
  <div class="floating-view__wrapper  ">

    <div @click="close()" class="animate__animated animate__fadeIn floating-view__backdrop">
      <div v-if="loading" class="loading-text__wrapper">
        <div class="loading-text">
          <typewrite>CARICAMENTO</typewrite>
        </div>
      </div>
    </div>

    <div class="floating-view__footer  animate__animated animate__slideInUp" v-if="post">
      <div class="container">
        <div class="row border-top pt-3">
          <div class="col-md-8 offset-md-2">
            <div class="d-flex align-items-center justify-content-between">

              <share-btns :btn-class="'btn-light'"></share-btns>

              <a rel="nofollow" data-ga-category="Ucraina" data-ga-label="click-campagna-uscita" data-ga-action="performance-enti" :href="post.url" v-if="post.url" target="_blank" class="ga-track btn iniziative-btn">

                <span>Vai all'iniziativa</span>
                <i class="ri-arrow-right-line"></i>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div v-if="post" class="floating-view__scroller animate__animated animate__slideInUp">

      <div  class="floating-view__container single-donazione">
        <div class="floating-view__close">
          <a @click="close()" class="">
            <i class="ri-close-line"></i>
          </a>
        </div>

        <div>
          <div class="">
            <div class="single-donazione__header-img" v-if="post.immagine">
              <img :src="`${post.immagine.original.fileUrl}`" alt="" class="">
            </div>


            <!--        <img src="https://sostieni.progettoarca.org/wp-content/uploads/2022/03/YCVL7543.jpg" class="img-fluid" alt="">-->
            <div class="row py-lg-5 py-3">
              <div class="col-lg-8 offset-lg-2">

                <h1 class="font-weight-bold">
                  {{ post.titolo }}
                </h1>

                <div v-if="tags" class="mb-5 d-flex align-items-center" style="line-height :1 ">
                  <span class="d-inline-flex align-items-center" v-for="tag in tags" :key="tag">
                    <i class="text-primary ri-hashtag"></i>
                    {{ tag }}
                  </span>
                </div>

                <div class="mb-5">
                  <small class="text-muted">Descrizione iniziativa:</small>
                  <p class="" v-html="parseContent(post.abstract)">

                  </p>
                </div>

                <div v-if="post.amount" class="mb-5">
                  <small class="text-muted">Ammontare:</small>
                  <div class="border-bottom  d-flex ">
                    <div class="mb-0 lead">{{ post.amount }} €</div>
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-md-6">
                    <div class="mb">
                      <small class="text-muted">Tipologia:</small>
                      <h6>
                        {{ post.type.label }}
                      </h6>
                    </div>

                    <div v-if="tipo" class="">
                      <small class="text-muted">Dettagli:</small>
                      <div class="">
                        <ul class="list-unstyled">
                          <li v-for="tipo in tipo" :key="tipo" class="py-2">
                            <h6>{{ tipo }}</h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 border-left" v-if="post.mezzo">
                    <div class="">
                      <small class="text-muted">Canali con cui puoi sostenere l'iniziativa:</small>
                      <div class="">
                        <ul class="list-unstyled">
                          <li v-for="mezzo in post.mezzo" :key="mezzo" class="py-2 ">
                            <h6>{{ mezzo }}</h6>
                          </li>
                        </ul>
                      </div>
                    </div>


                  </div>
                </div>


                <div class="mb-5">
                  <small class="text-muted">Promosso da:</small>
                  <div class=" ">
                    <div class="d-flex align-items-center">
                      <div class="mr-3">
                        <h4 class="mb-0">
                          {{ post.onp.name }}
                        </h4>
                      </div>
                      <div class="ml-auto">
                        <img :src="post.onp.logo.cropped.fileUrl" alt="" style="width: 60px;">
                      </div>
                    </div>
                    <p class="mt-3" v-html="parseContent(post.onp.description)"></p>

                    <div class="d-flex">
                      <i class="ri-map-pin-line ri-2x mr-3"></i>
                      <div>
                        <small class="text-muted">
                          Ha sede in </small>
                        <div>

                          {{ post.onp.sede_legale.city }}
                          ({{ post.onp.regione }})
                        </div>
                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Api from "@/Api";
import ShareBtns from "@/components/ShareBtns"
import router from "../../router";
import Typewrite from "@/components/Typewrite";
import HistoryManager from "@/components/HistoryManager";


const _ = require('lodash');

export default {
  data() {
    return {
      post: null,
      loading: true
    }
  },
  computed: {
    tags() {
      if (_.isArray(this.post.tags)) return this.post.tags;
      return this.post.tags.split(',')
    },
    tipo() {
      // se è un array glielo ritorno
      if (_.isArray(this.post.tipo)) return this.post.tipo;

      // se è una stringa
      if (this.post.tipo === 'Altro')
        return new Array(this.post.tipo_altro);

      return new Array(this.post.tipo);

    },
  },
  mounted() {
    document.body.classList.add('floating-view');

  },
  destroyed() {
    document.body.classList.remove('floating-view')
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchData()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  methods: {

    close() {
      //console.log("close",router.history._startLocation,HistoryManager.historyCount)
      if (HistoryManager.historyCount < history.length)
        router.back();
      else {
        //console.log(this.$route.params)
        router.push({name: 'Donazioni', params: {slug: this.$route.params.slug}})
      }
    },

    parseContent(content) {
      return content.replace(/\n/g, "<br />");
    },

    fetchData() {
      Api.get({
        action: 'web/ucraina/getlist',
        type: this.$route.params.type,
        id: this.$route.params.id
      }).then((response) => {
        this.post = response.data.hits.hits[0]._source;
        // fix per ios scroll


      })
          .finally(() => {
            this.loading = false;
          })
      //console.log("chiuditi")
    }
  },
  components: {
    Typewrite,
    ShareBtns
  }
}
</script>