import './installCompositionApi.js'
import Vue from 'vue'
import jQuery from 'jquery'
import { TagManager } from './utils/TagManager';
import { GaTrack } from './utils/gaTrack';
TagManager.settings.UID = process.env.VUE_APP_TAG_MANAGER_ID;
TagManager.init();
import App from './App.vue'
import router from './router'

import 'remixicon/fonts/remixicon.css'


//import bootstrap from '~/bootstrap'

/*
 TODO
  • GA
  • Tracciamento link esterni
  • pulsanti di share
  • hotjar
  • Meta statici
  • meta dinamici per serp

  la guerra è follia,
  fai qualcosa con i civili, fallo adesso
 */


Vue.config.productionTip = false

export const bus = new Vue();
new Vue({
    router,
    render: h => h(App)


}).$mount('#app');
(function ($) {
    'use strict'
    $(function () {

        $('body').on('click', '.ga-track', function () {
            var
                category = $(this).data('ga-category') || '',
                action = $(this).data('ga-action') || '',
                label = $(this).data('ga-label') || '';
            GaTrack.sendEventToAnalytics({
                type:'event',
                category:category,
                action:action,
                label:label,
            });
        });
    })
})(jQuery)

