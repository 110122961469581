import _ from "lodash";
import Regioni from "@/dictionary/Regioni";
import { reactive } from '@vue/composition-api'



export default reactive({
    count: 0,
    availables : {
        subtypes : [],
        regions : []
    },
    dictionary : {
        regions: Regioni,
    },

    setFilters(results) {
      this.setSubtypes(results);
      this.setRegions(results);
    },

    setRegions(results) {
        let res = {};
        _.forEach(results, (i) => {
            if (!res[i._source.onp.regione])
                res[i._source.onp.regione] = i._source.onp.regione
        });
        this.availables.regions = res
    },

    setSubtypes(results) {
        let res = {};
        _.forEach(results, (i) => {
            let post = i._source;

            // se è un array glielo ritorno
            if (_.isArray(post.tipo)) {
                _.forEach(post.tipo, (tipo) => {
                    if (!res[tipo])
                        res[tipo] = tipo;
                })

                // se è una stringa
           /* } else if (post.tipo === 'Altro') {
                if (!res[post.tipo_altro])
                    res[post.tipo_altro] = post.tipo_altro;*/
                //return new Array(this.post.tipo_altro);

            } else {
                if (!res[post.tipo])
                    res[post.tipo] = post.tipo;
            }
        });

        this.availables.subtypes = _(res).toPairs().sortBy(0).fromPairs().value();
        if(this.availables.subtypes["Altro"]){
            delete this.availables.subtypes["Altro"];
            this.availables.subtypes["Altro"] = "Altro"
        }
    }
})