const regioni =
    [{label : "Abruzzo" , slug : "abruzzo" },
        {label : "Basilicata" , slug : "basilicata" },
        {label : "Calabria" , slug : "calabria" },
        {label : "Campania" , slug : "campania" },
        {label : "Emilia-Romagna" , slug : "emilia-romagna" },
        {label : "Friuli-Venezia Giulia" , slug : "friuli-venezia-giulia" },
        {label : "Lazio" , slug : "lazio" },
        {label : "Liguria" , slug : "liguria" },
        {label : "Lombardia" , slug : "lombardia" },
        {label : "Marche" , slug : "marche" },
        {label : "Molise" , slug : "molise" },
        {label : "Piemonte" , slug : "piemonte" },
        {label : "Puglia" , slug : "puglia" },
        {label : "Sardegna" , slug : "sardegna" },
        {label : "Sicilia" , slug : "sicilia" },
        {label : "Toscana" , slug : "toscana" },
        {label : "Trentino-Alto Adige" , slug : "trentino-alto-adige" },
        {label : "Umbria" , slug : "umbria" },
        {label : "Valle d'Aosta" , slug : "valle-d-aosta" },
        {label : "Veneto" , slug : "veneto" }];
export default regioni;