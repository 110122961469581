<template>
  <div id="nav" :class="`${cls}`">
    <div class="container">

      <div id="nav-navbar">
        <router-link to="/" v-slot="{ href, route, navigate, isActive, isExactActive }">
          <a :href="href" @click="navigate" :class="[isExactActive && 'active']">
            <i class="ri-2x ri-service-line"></i>
            <span>Home</span>
          </a>
        </router-link>
        <router-link to="/aziende-e-istituzioni" v-slot="{ href, route, navigate, isActive, isExactActive }">
          <a :href="href" @click="navigate" :class="[isExactActive && 'active']">
            <i class="ri-2x ri-empathize-line"></i>
            <span>Aziende attive</span>
          </a>
        </router-link>
        <router-link to="/il-progetto" v-slot="{ href, route, navigate, isActive, isExactActive }">
          <a :href="href" @click="navigate" :class="[isExactActive && 'active']">
            <i class="ri-2x ri-information-line"></i>
            <span>Il Progetto</span>
          </a>
        </router-link>
        <router-link to="/segnalazioni" v-slot="{ href, route, navigate, isActive, isExactActive }" data-ga-category="Ucraina" data-ga-label="btn-segnalazione" data-ga-action="ux" >
          <a :href="href"
             @click="navigate" :class="[isExactActive && 'active']">
            <i class="ri-2x ri-add-circle-line"></i>
            <span class="d-none d-md-inline">Segnala un'iniziativa</span>
            <span class="d-md-none">Segnala</span>
          </a>
        </router-link>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MainNavbar',
  data() {
    return {
      scrollValues : {
        Home :  {
          in : window.innerHeight / 3 * 2,
          out : window.innerHeight / 3 * 2,
        }
      },
      cls: '',
      hiddenCls : 'invisible',
      activeCls : 'animate__animated animate__fadeInUp'
    }
  },

  created () {
    this.$watch(
        () => this.$route.params,
        () => {
          if(this.scrollValues[this.$route.name]) {
            window.addEventListener('scroll', this.handleScroll);
            this.cls = this.hiddenCls;
          } else {
            window.removeEventListener('scroll', this.handleScroll);
            this.cls = this.activeCls
          }
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )

  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      if(this.scrollValues[this.$route.name] && window.scrollY >= this.scrollValues[this.$route.name].in)
        this.cls = this.activeCls;
      else {
        this.cls = this.hiddenCls;
      }

      // Any code to be executed when the window is scrolled
    }
  }

}
</script>