const exampleData2 = [
    {
        id: 1,
        active: false,
        title: 'Come sono scelte le iniziative pubblicate?',
        details: `
      <p class="py-3 text-white mb-5">
              Le iniziative pubblicate sono promosse direttamente da un’organizzazione non profit o una pubblica amministrazione, sui suoi canali istituzionali o su una piattaforma di crowdfunding e che provengono da enti/donatori la cui identità è verificata.
            </p>
    `
    },
    {
        id: 2,
        active: false,
        title: 'Chi verifica le iniziative pubblicate?',
        details: `
      <p class="py-3 text-white mb-5">
              Sia nel caso degli enti, che nel caso dei donatori l’identità viene verificata dagli analisti di Italia
              non profit. Le organizzazioni non profit devono avere le caratteristiche richieste dal “<a target="_blank"
                                                                                                         href="https://italianonprofit.it/come-aderire/">percorso
              trasparenza</a>” della piattaforma.

            </p>
    `
    },
    {
        id: 3,
        active: false,
        title: `Come vengono usati i soldi/beni raccolti? Quali garanzie potete darmi su queste donazioni?`,
        details: `
      
            <p class="py-3 text-white mb-5">
              L’utilizzo effettivo delle risorse raccolte è a discrezione del beneficiario della raccolta. La verifica
              effettuata non garantisce il rispetto della destinazione dei fondi dichiarata dalle organizzazioni che
              potrà dipendere in larga parte anche dall’evoluzione dell’emergenza.
            </p>
    `
    },
    {
        id: 4,
        active: false,
        title: `Posso segnalare un'iniziativa?`,
        details: `
      <p class="py-3 text-white mb-5">
              Sì, puoi <a target="_blank" href="https://italianonprofit.it/strumenti/form-segnalazioni-ucraina-2022/">cliccare qui</a>
              e segnalare una iniziativa di un ente non profit, di una Pubblica Amministrazione, di una Azienda.
            </p>
    `
    },
    {
        id: 5,
        active: false,
        title: `Chi è Italia non profit?`,
        details: `
      <p class="py-3 text-white mb-5">
        Siamo la più grande community del Terzo Settore e dell’economia sociale. Attraverso la nostra piattaforma, <a href="https://italianonprofit.it/" target="_blank">italianonprofit.it</a>, promuoviamo l’accessibilità alle informazione per il bene delle comunità e dei territori. La piattaforma orienta cittadini e donatori nella scelta di enti non profit e di iniziative filantropiche verificate.

            </p>
    `
    }
];

export default exampleData2;