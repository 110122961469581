function gtag() {
    window.dataLayer.push(arguments);
}
export class TagManager {

    static initialized = false;
    static settings = {
        UID: process.env.TAG_MANAGER_ID,
        domains: ['fornonprofit.it', 'granter.it', 'italianonprofit.it']
    };

    static init() {
        if(TagManager.initialized) return;
        try {
            // Initialize the data layer for Google Tag Manager (this should mandatory be done before the Cookie Solution is loaded)
            window.dataLayer = window.dataLayer || [];

            // Default consent mode is "denied" for both ads and analytics, but delay for 2 seconds until the Cookie Solution is loaded
            // Default consent mode is "denied" for both ads and analytics as well as the optional types, but delay for 2 seconds until the Cookie Solution is loaded
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "granted", // optional
                personalization_storage: "denied", // optional
                security_storage: "denied", // optional
                wait_for_update: 2000 // milliseconds
            });
            // Improve ad click measurement quality (optional)
            gtag('set', 'url_passthrough', true);
            // Further redact your ads data (optional)
            gtag("set", "ads_data_redaction", true);
            // Google Tag Manager
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', TagManager.settings.UID);

            /*
            <!-- Google Tag Manager (noscript) -->
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N3LDL8S"
            height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
            <!-- End Google Tag Manager (noscript) -->
            */

            TagManager.initialized = true;
        } catch (error) {
            console.error(error);
        }

    }

}