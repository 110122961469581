<template>

  <div class="floating-view__wrapper floating-filters" v-if="active">
    <a @click="toggle()" class="animate__animated animate__fadeIn floating-view__backdrop">

    </a>
    <div class="floating-view__footer  animate__animated animate__slideInUp">
      <div class="container">
        <div class="row border-top pt-3">
          <div class="col-md-8 offset-md-2">

            <div class="d-flex flex-md-row flex-column align-items-center justify-content-between">

              <button class="btn btn-primary btn-block" @click="applyFilters">
                <span>Applica</span>
              </button>

              
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="floating-view__scroller animate__animated animate__slideInUp">
      <div class="floating-view__container ">
        <div class="floating-view__close">
          <a @click="toggle()" class="">
            <i class="ri-close-line"></i>
          </a>
        </div>

        <dl class="accordion box " role="presentation">


          <dt class="accordion-item-title">
            <button @click="toggleAccordion('subtype')" class="accordion-item-trigger">
              <h4 class="accordion-item-title-text"> {{category.serpFilterTitle}}</h4>
              <span class="accordion-item-trigger-icon"></span>
            </button>
          </dt>

          <transition
              name="accordion-item"
              @enter="startTransition"
              @after-enter="endTransition"
              @before-leave="startTransition"
              @after-leave="endTransition">
            <dd v-if="accordion.subtype" class="accordion-item-details">
              <div class="accordion-item-details-inner">
                <div class="">
                  <div>
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" id="allThree" value=""
                             v-model="checkedFilters.subtype">
                      <label class="custom-control-label" for="allThree">Tutte le iniziative</label>
                    </div>
                    <div class="custom-control custom-radio" v-for="filter in availableSubtype" :key="filter">
                      <input class="custom-control-input" type="radio" :id="filter" :value="filter"
                             v-model="checkedFilters.subtype">
                      <label class="custom-control-label" :for="filter">{{ filter }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </transition>


          <dt class="accordion-item-title">
            <button @click="toggleAccordion('regioni')" class="accordion-item-trigger">
              <h4 class="accordion-item-title-text"> Vedi le iniziative presenti in una specifica regione</h4>
              <span class="accordion-item-trigger-icon"></span>
            </button>
          </dt>

          <transition
              name="accordion-item"
              @enter="startTransition"
              @after-enter="endTransition"
              @before-leave="startTransition"
              @after-leave="endTransition">
            <dd v-if="accordion.regioni" class="accordion-item-details">
              <div class="accordion-item-details-inner">
                <div class="">
                  <div>
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" id="allRegions" value=""
                             v-model="checkedFilters.regioni">
                      <label class="custom-control-label" for="allRegions">Tutte le regioni</label>
                    </div>
                    <div class="custom-control custom-radio" v-for="filter in regions" :key="filter.slug">
                      <input class="custom-control-input" type="radio" :id="filter.slug" :value="filter.label"
                             v-model="checkedFilters.regioni" :disabled="isRegionAvailable(filter.label)">
                      <label class="custom-control-label" :for="filter.slug">{{ filter.label }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </transition>
        </dl>

      </div>
    </div>
  </div>

</template>

<script>

const _ = require('lodash');

import SubtypeFiltersStore from "@/dictionary/SubtypeFiltersStore";
import {bus} from '@/main'

export default {

  data() {
    return {
      accordion: {
        regioni: false,
        subtype: true
      },
      checkedFilters: {
        type: '',
        subtype: '',
        regioni: ''
      },
      regions: SubtypeFiltersStore.dictionary.regions,
      subtypes: SubtypeFiltersStore.availables.subtypes,
      active: false,
      frezeed: {},
      category:{}
    }
  },
  computed: {
    availableSubtype() {
      return SubtypeFiltersStore.availables.subtypes;
    },

    availableRegions: function () {
      return SubtypeFiltersStore.availables.regions
    },

    filtersChanged() {
      return !_.isEqual(this.frezeed, this.checkedFilters);
    }
  },

  created() {

    this.frezeed = _.cloneDeep(this.checkedFilters);

    bus.$on('toggleFilers', (obj) => {
      this.toggle();
      this.category = obj;
      this.frezeed = _.cloneDeep(this.checkedFilters);
    });
    bus.$on('resetFilters', () => {

      this.checkedFilters = {
        type: '',
        subtype: '',
        regioni: ''
      };
      this.accordion = {
        regioni: false,
        subtype: true
      };
    });
  },
  mounted() {


  },

  methods: {

    toggleAccordion(accordion) {
      _.forEach(this.accordion, (i, k) => {
        this.accordion[k] = false;
        this.checkedFilters[k] = '';
      })
      this.accordion[accordion] = true;
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    endTransition(el) {
      el.style.height = ''
    },


    applyFilters() {
      bus.$emit('applyFilters', this.checkedFilters);
      this.frezeed = _.cloneDeep(this.checkedFilters);
      this.toggle();
    },


    isRegionAvailable(regionSlug) {
      //console.log(regionSlug)
      return Object.keys(this.availableRegions).indexOf(regionSlug) < 0;
    },

    toggle: function () {
      this.active = !this.active;
      if (this.active)
        document.body.classList.add('floating-view')
      else
        document.body.classList.remove('floating-view')
    },

    getLabelForType(){
      console.log(this.category);
    }

  }
}

</script>