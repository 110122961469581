<template>

  <div class="view">
    <div class="h4uk__jumbo-home">
      <div class="container mb-auto">
        <div class="row">
          <div class="col-10 offset-xl-1">
            <a href="https://italianonprofit.it" target="_blank">
              <img src="@/assets/images/BIANCO.svg" alt="italia non profit" style="height: 20px;"
                   class="mb-4">
            </a>
          </div>
        </div>
      </div>
      <div class="container mb-auto">
        <div class="row">
          <div class="col-md-8 offset-xl-1 animate__animated animate__fadeIn">
            <div class="h4uk-headline">
              <h3 class="h4uk-headline__sup">
                Emergenza
              </h3>
              <h1 class="h4uk-headline__center">
                Ucraina
              </h1>
              <h3 class="h4uk-headline__sub">
                Come aiutare
              </h3>
            </div>
            <div class="scroll-indicator animate__animated animate__delay-3s animate__fadeIn"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary">
      <div class="container">
        <div class="row">

          <div class="col-xl-10 offset-xl-1">
            <lineswrite class="py-5">
              <p class=" h3 text-white font-weight-light " style="line-height: 1.5">
                La guerra è una follia, distrugge luoghi e vite. <br>
                È inaccettabile, è spaventosa. <span class="text-primary">Se anche tu senti il bisogno di fare qualcosa, ti trovi nel posto giusto.</span>
                Tutte le organizzazioni e le iniziative che troverai in queste pagine sono degne di fiducia e portano
                impatto aiutando concretamente le persone che fuggono dalla guerra. <br>
                <br>

              </p>
            </lineswrite>

            <div class="h3 text-center text-primary mb-md-5 mb-4">
              Scegli tu come attivarti <br class="d-md-none"> e con chi:
            </div>
          </div>

          <div class="col-xl-10 offset-xl-1">
            <ul class="h4uk-categorie">
              <li class="animate__animated animate__fadeIn" :style="`animation-delay : ${index * .2}s`"
                  v-for="(item, index) in filters.dictionary" :key="item.id">
                <router-link :to="`donazioni/${item.slug}`" class="h4uk-categorie__item ga-track"
                             data-ga-category="Ucraina" :data-ga-label="`btn-SERP-${item.slug}`" data-ga-action="ux">
                  <div class="h4uk-categorie__icon">
                    <i :class="`ri-${item.icon}-line`" class="ri-2x"></i>
                  </div>
                  <div class="h4uk-categorie__label">
                    <div class="h4uk-categorie__title">{{ item.message }}</div>

                  </div>
                </router-link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <div class="bg-secondary py-5">
      <hr>
      <div class="container">
        <div class="row py-5">
          <div class="col-xl-5 offset-xl-1">
            <accordion :content="faq"></accordion>
          </div>
          <div class="col-xl-4 offset-xl-1">
            <div class="position-sticky text-center text-md-left pt-5 pt-md-0" style="top:1rem">

              <h3 class="text-white font-weight-bold h3">
                Condividi, per fare la differenza
              </h3>
              <share-btns :btn-class="'btn-secondary'" class="mt-md-3"></share-btns>
              <p class="py-3  text-white mb-5">
                Attiva la tua rete, condividi sui tuoi canali
              </p>
            </div>
          </div>
        </div>
      </div>


      <footer class="">
        <div class="py-5">
          <div class="container">
            <div class="row justify-content-center no-gutters">
              <div class="col-lg-10 ">
                <div class="text-white p-md-3 text-center">
                  <small class=" ">
                    <small class="" style="opacity: .6">© 2022 Open Terzo Settore
                      <small class="line-height-1 d-block d-md-inline">
                        P.IVA: 09473230960 - Capitale sociale 10.600 €
                        Numero REA MI - 2092647
                      </small>
                      <div >
                        <a class="" href="https://italianonprofit.it/termini-e-condizioni-duso"  target="_blank">
                          Condizioni generali di utilizzo</a> <span class="">|</span>
                        <a class="" href="https://italianonprofit.it/privacy/" target="_blank">
                          Informativa sulla privacy</a> <span class="">|</span>
                        <a class="" href="https://italianonprofit.it/cookie-policy"  target="_blank">
                          Cookie policy
                        </a>
                      </div>
                    </small>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>
    </div>


  </div>
</template>

<script>
//import Typewrite from "../components/Typewrite";
import MainFilters from "@/dictionary/MainFilters";
import Lineswrite from "../components/Lineswrite";
import ShareBtns from "../components/ShareBtns"
import Accordion from "../components/Accordion"
import Faq from "@/components/Faq";
import {bus} from '@/main'
//import Lineswrite from "../components/Lineswrite";
// @ is an alias to /src
export default {
  name: 'Home',
  data() {
    return {
      filters: MainFilters,
      faq: Faq
    }
  },
  mounted() {
    bus.$emit('resetFilters')
  },
  components: {
    Lineswrite,
    ShareBtns,
    Accordion
    // Lineswrite
    //  Typewrite
  }
}
</script>
