<template>
  <div class="view segnalazione">
    <div class="">
      <div class="h4uk__jumbo-segnalazione">

        <div class="">
          <div class="container">
            <div class="row">
              <div class="col-10 offset-md-2">
                <a href="https://italianonprofit.it" target="_blank"><img src="@/assets/images/NERO.svg"
                                                                          alt="italia non profit" style="height: 20px;"
                                                                          class="mb-5"></a>
              </div>
              <div class="col-md-8 offset-xl-2 animate__animated animate__fadeIn ">
                <div class="h4uk-headline ">
                  <h1 class="h4uk-headline__center">
                    Segnala un'iniziativa
                  </h1>
                </div>
              </div>
              <div class="col-md-8 offset-md-2">
                <lineswrite>
                  <p class="lead mt-md-5">
                    Sei a conoscenza di una iniziativa in supporto delle persone in fuga dalla guerra e non la trovi in
                    questo sito?
                    <br>
                    Segnala ai nostri analisti, in questo modo la potremo verificare e pubblicare insieme alle altre.
                    La segnalazione è molto semplice, rispondi alle domande che seguono e in pochi clic la tua richiesta
                    sarà registrata.
                    <br>
                    <br>
                    Grazie per il tuo contributo!
                  </p>
                </lineswrite>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container py-5 ">
      <div class="row pb-5">
        <div class="col-md-8 offset-md-2">
          <div class="bg-white p-3 p-md-5 rounded shadow-lg">
            <div class="row">
              <div class="col-md-6 d-flex flex-column text-center">
                <span>Organizzazioni non profit</span>
                <a target="_blank" class="flex-fill d-flex align-items-center justify-content-center btn btn-primary btn-block mt-3" href="https://italianonprofit.it/iscriviti/?h4uk=1">
                  <div class="text-secondary font-weight-bold p-3 mb-0" style="font-size: 1.2rem; line-height: 1.2">
                    Voglio pubblicare l'iniziativa <span class="text-nowrap">del mio ente</span>
                  </div>
                </a>
              </div>
              <div class="col-md-6 d-flex flex-column text-center mt-5 mt-md-0">

                <span>Cittadini</span>
                <a target="_blank" class="flex-fill d-flex align-items-center justify-content-center btn btn-primary btn-block mt-3"
                   href="https://italianonprofit.it/strumenti/form-segnalazioni-ucraina-2022/">

                  <div class="text-secondary font-weight-bold p-3 mb-0" style="font-size: 1.2rem; line-height: 1.2">
                    Voglio segnalare <br>un'iniziativa di valore
                  </div>

                </a>
              </div>
            </div>
          </div>
        </div>


      </div>


    </div>
  </div>


</template>

<script>
import Lineswrite from "../components/Lineswrite";

export default {
  components: {
    Lineswrite
  }
}
</script>
