<template>
  <div id="app" >

    <main-navbar></main-navbar>
    <filtri-donazioni></filtri-donazioni>
    <transition  mode="out-in" :name="transitionName">
      <router-view />
    </transition>


  </div>
</template>

<style lang="scss">

@import "assets/styles/app";
@import "~animate.css/animate.css";

</style>

<script>




import MainNavbar from "@/components/MainNavbar";
import FiltriDonazioni from "@/components/FiltriDonazioni";
import HistoryManager from "@/components/HistoryManager";

export default {
  data () {
    return {
      transitionName: 'slide-left'
    }
  },
  components: {
    MainNavbar,
    FiltriDonazioni
  },
  beforeMount() {
    HistoryManager.historyCount = window.history.length;
  },
  beforeRouteUpdate (to, from, next) {
    console.log(to,from)
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'aninmated__animate animate_fadeInRight' : 'aninmated__animate animate_fadeInLeft'
    next()
  },
}
</script>